body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: "Montserrat" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li .tourgroup {
  font-weight: 600 !important;
  text-transform: uppercase !important;
  font-size: 13px !important;
  color: #222222 !important;
  float: left !important;
}
@media (min-width: 480px) {
  .carousel-caption {
    margin-bottom: 25px !important;
  }
}
@media (min-width: 768px) {
  .carousel-caption {
    margin-bottom: 60px !important;
  }
}
@media (min-width: 992px) {
  .carousel-caption {
    margin-bottom: 75px !important;
  }
}
@media (min-width: 1096px) {
  .carousel-caption {
    margin-bottom: 15% !important;
  }
}
@media (min-width: 1200px) {
  .carousel-caption {
    margin-bottom: 15% !important;
  }
}
.item {
  overflow: hidden;
}

.item > img {
  vertical-align: middle; /* remove the vertical gap under inline level elements */
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.isotopeFilters ul.filter > li a {
  color: black;
}
.thumbnail.deals .caption .detailsInfo h5 span {
  color: black;
}
.buttonTransparent {
  color: black;
}
.availabilitydate {
  font-family: "Roboto" !important;
}

.video_container {
  min-height: 40vh;
  /* max-height: 100vh; */
  overflow: hidden;
  position: relative;
}

.video_container video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}